import mail from "../Assets/email.png";
import instagram from "../Assets/instagram.png";
import whatsapp from "../Assets/whatsapp.png";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import globalicon from "../Assets/globo.png";

const NavBar = ({ open, handleClick, setOpen }) => {
  const [currentLanguage, setCurentLanguage] = useState(
    localStorage.getItem("LANGUAGE") || "en"
  );

  const changeLanguage = () => {
    const newLanguage = currentLanguage === "es" ? "en" : "es";
    i18n.changeLanguage(newLanguage);

    setCurentLanguage(newLanguage);
    localStorage.setItem("LANGUAGE", newLanguage);
  };

  const handleCloseMenu = () => {
    setOpen(false)
  }

  const { t } = useTranslation();

  return (
    <div className={`nav-container ${open ? "nav-container-open" : ""}`}>
      <button className="nav-close" onClick={handleClick}>
        <span></span>
      </button>

      <nav>
        <ul>
          <li>
            <a
              className={`item-navegacion ${
                open ? "item-navegacion-open" : ""
              }`}
              href="#inicio"
              onClick={handleCloseMenu}
            >
              {t("menu.home")}
            </a>
          </li>
          <li>
            <a
              className={`item-navegacion ${
                open ? "item-navegacion-open" : ""
              }`}
              href="#servicios"
              onClick={handleCloseMenu}
            >
              {t("menu.ourServices")}
            </a>
          </li>
          <li>
            <a
              className={`item-navegacion ${
                open ? "item-navegacion-open" : ""
              }`}
              href="#portfolio"
              onClick={handleCloseMenu}
            >
              {t("menu.portfolio")}
            </a>
          </li>
          <li>
            <a
              className={`item-navegacion ${
                open ? "item-navegacion-open" : ""
              }`}
              href="#contacto"
              onClick={handleCloseMenu}
            >
              {t("menu.contact")}
            </a>
          </li>
        </ul>

        <div className="languague-section">
          <button onClick={changeLanguage}>
            <img src={globalicon} alt="icono global" />
            <p>{currentLanguage === "es" ? "English" : "Spanish"}</p>
          </button>
        </div>

        <p>Comunicate con nosotros y transformá tu proyecto para siempre.</p>

        <div>
          <a href="">
            <img
              className="logo-redes"
              src={mail}
              alt="logo de correo electronico"
            />
          </a>
          <a href="https://www.instagram.com/athom_digitales/" target="_blank">
            <img
              className="logo-redes"
              src={instagram}
              alt="logo de instagram"
            />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=541144079115"
            target="_blank"
          >
            <img className="logo-redes" src={whatsapp} alt="logo de whatsapp" />
          </a>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
