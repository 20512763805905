import background from "../Assets/background-video.mp4";
import responsive from "../Assets/home-responsive-background.mp4";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  return (
    <div id="inicio" className="principal-home-container">
      <video
        className="background-video"
        autoPlay
        loop
        muted
        playsInline
        /*  poster={primerfondo} */
      >
        <source src={background} type="video/mp4" />
      </video>

      <video
        className="video-responsive"
        /*     poster={fondo} */
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={responsive} type="video/mp4" />
      </video>
      <div className="mask"></div>
      <div className="wrapper">
        <h1>
          {t("home.homeText1")} <br />
          {t("home.homeText2")}
        </h1>
      </div>
    </div>
  );
};

export default Home;
