import HamburgerMenu from "./HamburgerMenu";
import NavBar from "./NavBar";
import { useEffect, useState } from "react";
import logoAthom from "../Assets/logo-white.png";

const Header = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
    document.querySelector("html").classList.toggle("menu-open");
  };

  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);

  // Le agrega una clase al header al scrollear mas de x pixeles, para que se vea bien
  useEffect(() => {
    const handleScroll = (e) => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 96);
    };

    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, []);

  return (
    <>
      <div
        className={`header-container ${
          isScrollValueMoreThanHeaderHeight ? "menuscroll" : ""
        }`}
      >
        <div className="header-details">
          <div className="wrapper">
            <a href="#inicio">
              <img
                className="logo-principal"
                src={logoAthom}
                alt="Logo principal de Athom"
              />
            </a>

            <div>
              <div>
                <HamburgerMenu
                  classname={`hamburguer-menu`}
                  handleClick={handleClick}
                />
              </div>

              <NavBar setOpen={setOpen} handleClick={handleClick} open={open} />
            </div> 
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
