import Home from "./Home";
import AboutUs from "./AboutUs";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import Footer from "./Footer";

const Main = () => {
  return (
    <>
      <Home />
      <AboutUs />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
};

export default Main;
