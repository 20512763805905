import { useTranslation } from "react-i18next";
import diseño from "../Assets/diseño-icon.png";
import desarrollo from "../Assets/desarrollo-icon.png";
import tienda from "../Assets/tienda-online.png";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <div id="servicios" className="about-us-container">
        <div className="wrapper about-us-details">
          <h1>{t("aboutUs.weDo")}</h1>
          <h2>
            {t("aboutUs.weDoDetails1")}
            <br /> {t("aboutUs.weDoDetails2")} <br />{" "}
            {t("aboutUs.weDoDetails3")}
          </h2>
          <h3>{t("aboutUs.weOffer1")}</h3>
        </div>
        <div className="wrapper services-container">
          <div>
            <div className="web-design">
              <img src={diseño} alt="" />
              <h1>{t("aboutUs.services1")}</h1>
            </div>

            <h2>{t("aboutUs.servicesDetails1")}</h2>
          </div>
          <div>
            <div className="web-development">
              <img src={desarrollo} alt="" />
              <h1>{t("aboutUs.services2")}</h1>
            </div>

            <h2> {t("aboutUs.servicesDetails2")}</h2>
          </div>
          <div>
            <div className="online-store">
              <img src={tienda} alt="" />
              <h1>{t("aboutUs.services3")}</h1>
            </div>

            <h2>{t("aboutUs.servicesDetails3")}</h2>
          </div>
        </div>
      </div>
      <div className="line wrapper"></div>
    </>
  );
};

export default AboutUs;
