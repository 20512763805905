import mail from "../Assets/email-black.png";
import instagram from "../Assets/instagram-black.png";
import whatsapp from "../Assets/whatsapp-black.png";
import athom from "../Assets/logo-black.png";
/* import background from "../Assets/pre-footer-final.png"; */
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <img className="wolf-background" src={background} alt="" /> */}
      <div className="footer-container">
        <div className="wrapper footer-details">
          <div className="footer-section">
            <div className="footer-section-1">
              <a href="#inicio">
                <img
                  src={athom}
                  className="logo-athom"
                  alt="logo principal de Athom"
                />
              </a>
            </div>
            <div className="separator-sections"></div>

            <div className="footer-section-2">
              <div>
                <a href="">
                  <img
                    className="logo-redes-footer"
                    src={mail}
                    alt="logo de correo electronico"
                  />
                </a>
                <a
                  href="https://www.instagram.com/athom_digitales/"
                  target="_blank"
                >
                  <img
                    className="logo-redes-footer"
                    src={instagram}
                    alt="logo de instagram"
                  />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=541144079115"
                  target="_blank"
                >
                  <img
                    className="logo-redes-footer"
                    src={whatsapp}
                    alt="logo de whatsapp"
                  />
                </a>
              </div>
              <p>Copiright Athom 2024</p>
            </div>
            <div className="separator-sections"></div>
            <div className="footer-section-3">
              <a
                href="https://api.whatsapp.com/send?phone=541144079115"
                target="_blank"
              >
                {" "}
                +54 (011) 44079115
              </a>
              <p>athomdigital@gmail.com</p>
            </div>
          </div>
          <div className="footer-section-4">
            <p>{t("footer.design")}</p>
            <a href="https://www.behance.net/lorenzopav763c" target="_blank">
              Gosky
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
