/* import Rosee from "../Assets/Portolio/rosee-image.png";
import any from "../Assets/Portolio/anynatural-image.png";
import servicepro from "../Assets/Portolio/servicepro-image.png";
import automationbg from "../Assets/Portolio/automationbg-image.png";
import serena from "../Assets/Portolio/serena-portfolio.png";
import roseeresponsive from "../Assets/Portolio/rosee-responsive.png";
import anyresponsive from "../Assets/Portolio/anynatural-responsive.png";
import serviceresponsive from "../Assets/Portolio/servicepro-responsive.png";
import automationresponsive from "../Assets/Portolio/automationbg-responsive.png";
import serenaresponsive from "../Assets/Portolio/serena-responsive.png";
import { useTranslation } from "react-i18next";

const Portfolio = () => {
  const { t } = useTranslation();
  return (
    <>
      <div id="portfolio" className="portfolio-container">
        <div className="wrapper portfolio-details">
          <h1>{t("portfolio.clients")}</h1>
          <h2>
            {t("portfolio.works1")} <br /> {t("portfolio.works2")}
          </h2>
        </div>
      </div>
      <div className="websites-principal">
        <div className="websites-container">
          <div className="web-details">
            <a href="https://roseemissions.com/">
              <img
                className="portfolio-web"
                src={Rosee}
                alt="pagina de Rosee Missions"
              />
              <img
                className="portfolio-responsive"
                src={roseeresponsive}
                alt=""
              />
            </a>
          </div>
          <div>
            <div className="web-details">
              <a href="https://anynatural.com.ar/" target="_blank">
                {" "}
                <img
                  className="portfolio-web"
                  src={any}
                  alt="pagina de Any natural"
                />
                <img
                  className="portfolio-responsive"
                  src={anyresponsive}
                  alt=""
                />
              </a>
            </div>
            <div className="web-details">
              <a href="https://servicepro.com.ar/" target="_blank">
                <img
                  className="portfolio-web"
                  src={servicepro}
                  alt="pagina de Service Pro"
                />
                <img
                  className="portfolio-responsive"
                  src={serviceresponsive}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="websites-container">
          <div className="web-details">
            <a href="https://www.automationbg.com.ar/" target="_blank">
              {" "}
              <img
                className="portfolio-web"
                src={automationbg}
                alt="pagina de Automation bg"
              />
              <img
                className="portfolio-responsive"
                src={automationresponsive}
                alt=""
              />
            </a>
          </div>
          <div className="web-details">
            <a href="https://www.espacioserena.com.ar/" target="_blank">
              <img
                className="portfolio-web"
                src={serena}
                alt="pagina de Serena Holistica"
              />
              <img
                className="portfolio-responsive"
                src={serenaresponsive}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>

      <div className="line wrapper"></div>
    </>
  );
};

export default Portfolio; */

import React, { useEffect, useState } from "react";
import Rosee from "../Assets/Portolio/rosee-image.png";
import any from "../Assets/Portolio/anynatural-image.png";
import servicepro from "../Assets/Portolio/servicepro-image.png";
import automationbg from "../Assets/Portolio/automationbg-image.png";
import serena from "../Assets/Portolio/serena-portfolio.png";
import roseeresponsive from "../Assets/Portolio/rosee-responsive.png";
import anyresponsive from "../Assets/Portolio/anynatural-responsive.png";
import serviceresponsive from "../Assets/Portolio/servicepro-responsive.png";
import automationresponsive from "../Assets/Portolio/automationbg-responsive.png";
import serenaresponsive from "../Assets/Portolio/serena-responsive.png";
import { useTranslation } from "react-i18next";

const Portfolio = () => {
  const { t } = useTranslation();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("portfolio");
      if (element) {
        const boundingBox = element.getBoundingClientRect();
        const isVisible =
          boundingBox.top < window.innerHeight && boundingBox.bottom >= 0;
        if (isVisible && !animate) {
          setAnimate(true);
        }
      }
    };

    // Escucha el evento de scroll
    window.addEventListener("scroll", handleScroll);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Deja la dependencia vacía para que se ejecute después del montaje

  return (
    <>
      <div id="portfolio" className="portfolio-container">
        <div className="wrapper portfolio-details">
          <h1>{t("portfolio.clients")}</h1>
          <h2>
            {t("portfolio.works1")} <br /> {t("portfolio.works2")}
          </h2>
        </div>
      </div>
      <div className={`websites-principal ${animate ? "animate" : ""}`}>
        <div className="websites-container">
          <div className="web-details">
            <a href="https://roseemissions.com/">
              <img
                className={`portfolio-web ${animate ? "animated" : ""}`}
                src={Rosee}
                alt="pagina de Rosee Missions"
              />
              <img
                className={`portfolio-responsive ${animate ? "animated" : ""}`}
                src={roseeresponsive}
                alt=""
              />
            </a>
          </div>
          <div>
            <div className="web-details">
              <a href="https://anynatural.com.ar/" target="_blank">
                {" "}
                <img
                  className={`portfolio-web ${animate ? "animated" : ""}`}
                  src={any}
                  alt="pagina de Any natural"
                />
                <img
                  className={`portfolio-responsive ${
                    animate ? "animated" : ""
                  }`}
                  src={anyresponsive}
                  alt=""
                />
              </a>
            </div>
            <div className="web-details">
              <a href="https://servicepro.com.ar/" target="_blank">
                <img
                  className={`portfolio-web ${animate ? "animated" : ""}`}
                  src={servicepro}
                  alt="pagina de Service Pro"
                />
                <img
                  className={`portfolio-responsive ${
                    animate ? "animated" : ""
                  }`}
                  src={serviceresponsive}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="websites-container">
          <div className="web-details">
            <a href="https://www.automationbg.com.ar/" target="_blank">
              {" "}
              <img
                className={`portfolio-web ${animate ? "animated" : ""}`}
                src={automationbg}
                alt="pagina de Automation bg"
              />
              <img
                className={`portfolio-responsive ${animate ? "animated" : ""}`}
                src={automationresponsive}
                alt=""
              />
            </a>
          </div>
          <div className="web-details">
            <a href="http://www.espacioserena.com.ar/" target="_blank">
              <img
                className={`portfolio-web ${animate ? "animated" : ""}`}
                src={serena}
                alt="pagina de Serena Holistica"
              />
              <img
                className={`portfolio-responsive ${animate ? "animated" : ""}`}
                src={serenaresponsive}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div className="line wrapper"></div>
    </>
  );
};

export default Portfolio;
