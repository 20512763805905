import mail from "../Assets/email.png";
import instagram from "../Assets/instagram.png";
import whatsapp from "../Assets/whatsapp.png";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div id="contacto" className="contact-container">
      <div className="wrapper">
        <h1> {t("contact.change")}</h1>
        <h2>{t("contact.contactHere")}</h2>

        <div>
          <p>athomdigital@gmail.com</p>
        </div>
        <span className="city-info">{t("contact.city")}</span>
        <div>
          <a
            href="https://api.whatsapp.com/send?phone=541144079115"
            target="_blank"
          >
            {" "}
            +54 (011) 44079115
          </a>
        </div>
        <div className="contact-redes">
          <a href="">
            <img
              className="logo-redes"
              src={mail}
              alt="logo de correo electronico"
            />
          </a>
          <a href="https://www.instagram.com/athom_digitales/" target="_blank">
            <img
              className="logo-redes"
              src={instagram}
              alt="logo de instagram"
            />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=541144079115"
            target="_blank"
          >
            <img className="logo-redes" src={whatsapp} alt="logo de whatsapp" />
          </a>
        </div>
      </div>
      {/*       <div className="contact-background"></div> */}
    </div>
  );
};
export default Contact;
